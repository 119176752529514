import { API, GraphQLResult } from "@aws-amplify/api";
import { Cache } from "aws-amplify";
import gql from "graphql-tag";
import { AuthType, IGetUser, IUserData } from "./types";

export const getUserRole = async () => {
  const fields = ["userPersonalInfo"];
  try {
    const idToken = Cache.getItem("token");
    const userData = (await API.graphql(
      {
        query: gql`
          query getUser($fields: [GetUserFields]!) {
            getUser(fields: $fields) {
              data {
                userPersonalInfo {
                  userRole
                  fullName
                  userId
                }
              }
              error {
                code
                message
              }
            }
          }
        `,
        variables: {
          fields,
        },
        authMode: AuthType.cognitoAuth,
      },
      { idToken },
    )) as GraphQLResult<IUserData>;

    return userData.data as IGetUser;
  } catch (error) {
    return error;
  }
};
