import { CssBaseline, GlobalStyles } from "@mui/material";

const GlobalCss = () => {
  return (
    <>
      <CssBaseline />
      <GlobalStyles
        styles={{
          "*": {
            fontFamily: "'Inter', sans-serif !important; ",
          },
        }}
      />
    </>
  );
};

export default GlobalCss;
