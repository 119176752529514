import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserPool } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { awsConfig } from "../awsConfig";
import { logger } from "../utils/logger/logger";

export const setAuth = async (idToken: string, accessToken: string, refreshToken: string) => {
  try {
    const AccessToken = new CognitoAccessToken({
      AccessToken: accessToken,
    });
    const localSession = new AmazonCognitoIdentity.CognitoUserSession({
      IdToken: new CognitoIdToken({
        IdToken: idToken,
      }),
      AccessToken,
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: refreshToken,
      }),
    });
    const poolData = {
      UserPoolId: awsConfig.Auth.userPoolId as string,
      ClientId: awsConfig.Auth.userPoolWebClientId as string,
    };
    const userPool = new CognitoUserPool(poolData);
    const localUser = new AmazonCognitoIdentity.CognitoUser({
      Username: AccessToken.payload.username,
      Pool: userPool,
    });
    localUser.setSignInUserSession(localSession);
    // this seems like a hack
    Auth.currentSession = async () => localSession;
  } catch (error) {
    logger.debug("setAuth-ERROR", error);
  }
};

export const clearAuth = () => {
  const poolData = {
    UserPoolId: awsConfig.Auth.userPoolId as string,
    ClientId: awsConfig.Auth.userPoolWebClientId as string,
  };
  const userPool = new CognitoUserPool(poolData);
  userPool.getCurrentUser()?.signOut();

  const localSession = new AmazonCognitoIdentity.CognitoUserSession({
    IdToken: new CognitoIdToken({
      IdToken: "",
    }),
    AccessToken: new CognitoAccessToken({
      AccessToken: "",
    }),
    RefreshToken: new CognitoRefreshToken({
      RefreshToken: "",
    }),
  });

  // Clear the Auth session
  Auth.currentSession = async () => localSession;
};
