import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AmplifyConfig from "./config/amplify";
import GlobalCss from "./global/muiGlobalCss";
import reportWebVitals from "./reportWebVitals";
import Auth from "./services/auth";
import { logger } from "./utils/logger/logger";

new AmplifyConfig();
Auth.init()
  .then(() => logger.debug("Initiated Auth"))
  .catch(() => logger.debug("Auth Error"));

ReactDOM.render(
  <React.StrictMode>
    <GlobalCss />
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
