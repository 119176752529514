import { Amplify, Cache } from "aws-amplify";
import { awsConfig } from "../awsConfig";

class AmplifyConfig {
  static setAuthHeader = () => {
    const token = Cache.getItem("token");
    if (token) {
      Amplify.configure({
        API: {
          graphql_headers: async () => ({
            authorization: token,
          }),
        },
      });
    }
  };

  constructor() {
    this.init();
  }

  private init = () => {
    Amplify.configure(awsConfig);
  };
}

export default AmplifyConfig;
