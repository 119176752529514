import { useContext, createContext } from "react";
import { Editor } from "slate";

export interface ISelectedQuestionSetData {
  questionSetId: string | null;
  authorId: string | null;
  title: string | null;
}

export interface ITitleBarData {
  title?: React.ReactNode | string;
  subTitle?: string;
  questionTitle?: string;
  backButton?: boolean;
  questionNumber?: number;
  displayQuestionNo?: boolean;
}

export type AppContextType = {
  dashboardTitle: string;
  isAuthenticated: boolean;
  questionSetData: string;
  setDashboardTitle: React.Dispatch<React.SetStateAction<string>>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionSetData: React.Dispatch<React.SetStateAction<string>>;
  titleBarData: ITitleBarData;
  setTitleBarData: React.Dispatch<React.SetStateAction<ITitleBarData>>;
  setUserFullName: React.Dispatch<React.SetStateAction<string>>;
  setUserID: React.Dispatch<React.SetStateAction<string>>;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  setUserRole: React.Dispatch<React.SetStateAction<string>>;
  userFullName: string;
  userID: string;
  username: string;
  userRole: string;
  isImagePickerOpen: boolean;
  setIsImagePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editor: Editor | null;
  setEditor: React.Dispatch<React.SetStateAction<Editor | null>>;
  isMathJaxModalOpen: boolean;
  setIsMathJaxModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContext = createContext<AppContextType | null>(null);

export const useAppContext = () => useContext(AppContext);
