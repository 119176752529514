import { CoverImageUrlType } from "../pages/questionSet/types/enums";

export enum AuthType {
  cognitoAuth = "AMAZON_COGNITO_USER_POOLS",
  iamAuth = "AWS_IAM",
}

export enum UserRoles {
  CONTRIBUTOR = "contributor",
}

export interface ILoginResult {
  login: ILoginItem | null;
}

export interface ILoginItem {
  data: {
    access: string | null;
    errMsg: string | null;
    refresh: string | null;
    token: string | null;
  };
  error: ILoginError;
}

export interface ILoginError {
  code: string;
  message: string;
}

export interface ILocations {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface IInputQuestionPaperSet {
  authorId: string;
  authorName: string;
  discipline: string;
  disciplineCode: string;
  examType: string;
  examTypeCode: string;
  medium: string;
  noOfQuestions: number;
  paperTime: string;
  paperType: string;
  questionSetId: string;
  title: string;
  coverImageUrl?: string | null;
  coverImageUrlType?: CoverImageUrlType;
  difficulty?: string;
  level?: string[];
  levelCode?: string[];
  subtopics?: ISubTopicSaveItem[];
  referenceData?: string | null;
}

export interface IGetCommonReferenceData {
  getCommonReferenceData: {
    data: IReference;
  };
}

export interface IReferenceError {
  errors?: string[];
}

export interface ICurrentSession {
  token: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  name: string;
}

export interface ITokenData {
  email: string;
  given_name: string;
  family_name: string;
  "cognito:username": string;
  exp: number;
  name: string;
}

export interface IError {
  code: string;
  message: string;
}

export type RefreshTokenQuery = {
  getNewTokens?: {
    data: {
      token?: string | null;
      access?: string | null;
      refresh?: string | null;
    };
    error?: IError;
  } | null;
};

export interface IDifficultyLevels {
  levels: string[] | null;
}

export interface IPaperTypes {
  paperTypes: IPaperTypeItem[] | null;
}

export interface IPaperTypeItem {
  name: string;
}

export interface ILanguages {
  languages: string[] | null;
}

export interface IReference {
  difficultyLevels: IDifficultyLevels;
  paperTypes: IPaperTypes;
  languages: ILanguages;
}

export interface IAuthorsDataByUserReference {
  getUserReferenceData: {
    data: {
      examReference: IExamReference[] | null;
    };
  };
}

export interface IAuthorsResult {
  getAuthors: IAuthorsData | null;
  errors?: IAuthorsError[];
}

export interface IAuthorsError {
  data: string | null;
  errorInfo: string | null;
  errorType: string | null;
  locations: string[];
  message: string | null;
  path: string[];
}
export interface IAuthorsData {
  data: {
    userPersonalInfo: IAuthUserPersonalInfo;
    examReference: IExamReference[] | null;
  }[];
}

export interface IExamReference {
  code: string;
  exmName: string;
}

export interface IAuthUserPersonalInfo {
  userId: string;
  fullName: string;
}

export interface IPaginationKey {
  userId: string | null;
}
export type SignOutQuery = {
  signOut?: {
    data?: string | null;
  } | null;
  error?: IError;
};

export interface IDeleteQuestionResult {
  deleteQuestion: {
    data: string;
    error?: IError;
  };
}

export interface IUserData {
  data?: IGetUser;
}

export interface IGetUser {
  getUser: {
    data: {
      userPersonalInfo: IUserPersonalInfo;
    };
    error?: IError;
  };
}

export interface IUserPersonalInfo {
  fullName: string;
  userId: string;
  userRole: string;
}

/* getExamData */
export interface IDisciplines {
  code: string;
  coverImageUrl: string | null;
  shortName: string | null;
}

export interface ILevels {
  code: string;
  name: string;
}

interface IExamData {
  code: string;
  name: string;
  disciplines: IDisciplines[] | null;
  levels: ILevels[] | null;
}
export interface IGetExamData {
  getAExam: {
    data: IExamData;
    error?: IError;
  };
}

/* getSubTopics */

export interface IGetSubTopics {
  getSubTopicsByDiscipline: {
    data: ISubTopicSet[] | null;
    error?: IError;
  };
}

export interface IGetFilteredSubTopics {
  getSubTopicsByDisciplineAndLevel: {
    data: ISubTopicSet[] | [];
    error?: IError;
  };
}

export interface ISubTopicSet {
  sk: string;
  levelName: string;
  subtopics: ISubtopicItem[] | null;
}

export interface ISubtopicItem {
  id: string;
  name: string;
}

export interface ISubTopicSaveItem {
  subtopicId: string;
  subtopicName: string;
  levelCode?: string;
  levelName?: string;
}

// @TODO check if the subtopics interfaces are duplicated
export interface IQuestionResultSubtopic {
  levelCode: string | null;
  levelName: string | null;
  subtopicId: string | null;
  subtopicName: string | null;
}

export interface ISubtopic {
  id: string;
  name: string;
}

export interface IGetSubTopicsByDisciplineResult {
  getSubTopicsByDisciplineAndLevel: {
    data: {
      sk: string;
      subtopics: ISubtopic[];
    }[];
    error: string;
  };
}

export interface IGetSubTpoicsByDisciplineError {
  errors: string;
}
