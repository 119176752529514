import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const UnauthenticatedRouteComponent = React.lazy(() =>
  import("./unauthenticatedRoute").then(({ UnauthenticatedRoute }) => ({
    default: UnauthenticatedRoute,
  })),
);

const AuthenticatedRouteComponent = React.lazy(() =>
  import("./authenticatedRoute").then(({ AuthenticatedRoute }) => ({
    default: AuthenticatedRoute,
  })),
);

const Login = React.lazy(() => import("../pages/login"));

const Dashboard = React.lazy(() => import("../layouts/dashboard"));

const QuestionSetTable = React.lazy(() => import("../pages/questionSet"));

const QuestionsTable = React.lazy(() => import("../pages/viewQuestions"));

const CreateQuestion = React.lazy(() => import("../pages/createQuestion"));

const Routers: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route
            path=""
            element={
              <AuthenticatedRouteComponent>
                <QuestionSetTable />
              </AuthenticatedRouteComponent>
            }
          />
          <Route path="questions">
            <Route
              path="modify"
              element={
                <AuthenticatedRouteComponent>
                  <QuestionsTable isViewOnly={false} />
                </AuthenticatedRouteComponent>
              }
            />
            <Route path="modify">
              <Route
                path="create"
                element={
                  <AuthenticatedRouteComponent>
                    <CreateQuestion isEdit={false} isViewOnly={false} />
                  </AuthenticatedRouteComponent>
                }
              />
              <Route
                path="edit"
                element={
                  <AuthenticatedRouteComponent>
                    <CreateQuestion isEdit />
                  </AuthenticatedRouteComponent>
                }
              />
            </Route>
            <Route path="view-only">
              <Route
                path="question"
                element={
                  <AuthenticatedRouteComponent>
                    <CreateQuestion isViewOnly />
                  </AuthenticatedRouteComponent>
                }
              />
            </Route>
          </Route>
          <Route
            path="questions-view-only"
            element={
              <AuthenticatedRouteComponent>
                <QuestionsTable isViewOnly />
              </AuthenticatedRouteComponent>
            }
          />
        </Route>
        <Route
          path="/login"
          element={
            <UnauthenticatedRouteComponent>
              <Login />
            </UnauthenticatedRouteComponent>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Routers;
