export const clientId = process.env.REACT_APP_CLIENT_ID;
export const userPoolId = process.env.REACT_APP_USER_POOL_ID;

export const awsConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
};
