import { GraphQLResult } from "@aws-amplify/api";
import { API, Cache } from "aws-amplify";
import gql from "graphql-tag";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { clearAuth, setAuth } from "./authService";
import {
  AuthType,
  ICurrentSession,
  ILoginError,
  ILoginResult,
  ITokenData,
  RefreshTokenQuery,
  SignOutQuery,
} from "./types";
import { logger } from "../utils/logger/logger";

class Auth {
  static loginInterval: number;

  static init = async () => {
    logger.debug("Auth class init");
    const auth = new Auth();
    await auth.refreshToken();
  };

  static currentUserSession = (): ICurrentSession | null => {
    const token = Cache.getItem("token");
    if (token) {
      const tokenData: ITokenData = jwtDecode(Cache.getItem("token"));
      return {
        token,
        email: tokenData.email,
        firstName: tokenData.given_name,
        lastName: tokenData.family_name,
        username: tokenData["cognito:username"],
        name: tokenData.name,
      };
    }
    return null;
  };

  static signIn = async (username: string, password: string) => {
    try {
      const input = {
        username,
        password,
      };
      const result = (await API.graphql(
        {
          query: gql`
            mutation login($input: InputLogin) {
              login(input: $input) {
                data {
                  access
                  refresh
                  token
                }
                error {
                  code
                  message
                }
              }
            }
          `,
          variables: {
            input,
          },

          authMode: AuthType.iamAuth,
        },
        {
          appClientId: process.env.REACT_APP_CLIENT_ID as string,
        },
      )) as GraphQLResult<ILoginResult>;

      if (!result) {
        return null;
      }

      if (result.data) {
        const accessToken = result.data?.login?.data?.access;
        const refreshToken = result.data?.login?.data?.refresh;
        const token = result.data?.login?.data?.token;
        const auth = new Auth();
        if (token && refreshToken && accessToken) {
          auth.setTokenLocalStorage(token, accessToken, refreshToken);
          await setAuth(token, accessToken, refreshToken);
        }

        await auth.refreshToken();
      }
      return result.data as ILoginResult;
    } catch (error) {
      return error as ILoginError;
    }
  };

  static signOut = async () => {
    try {
      (await API.graphql(
        {
          query: gql`
            query signOut {
              signOut {
                data
                error {
                  code
                  message
                }
              }
            }
          `,
        },
        { appClientId: process.env.REACT_APP_CLIENT_ID as string },
      )) as GraphQLResult<SignOutQuery>;
    } catch (error) {
      logger.error("signOut-ERROR", error);
    }
    Cache.clear();
    window.location.reload();
    clearTimeout(Auth.loginInterval);
    clearAuth();
  };

  private setTokenLocalStorage = (token: string, access: string, refresh: string) => {
    const tokenData: ITokenData = jwtDecode(access);
    Cache.setItem("token", token, {
      priority: 1,
      expires: tokenData.exp * 1000,
    });
    Cache.setItem("access", access, {
      priority: 1,
      expires: tokenData.exp * 1000,
    });
    Cache.setItem("refresh", refresh, {
      priority: 1,
      expires: tokenData.exp * 1000,
    });

    if (Auth.loginInterval) {
      clearTimeout(Auth.loginInterval);
    }

    const timeDiff = tokenData.exp * 1000 - moment().unix() * 1000;
    Auth.loginInterval = window.setTimeout(() => {
      if (!Cache.getItem("token") || !Cache.getItem("access") || !Cache.getItem("refresh")) {
        window.location.href = "/login";
      }
    }, timeDiff);
  };

  private refreshTokeTimer = () => {
    const stageRefreshTime = process.env.REACT_APP_TOKEN_REFRESH_TIME;
    const refreshTime: number =
      stageRefreshTime && parseInt(stageRefreshTime, 10) > 5
        ? parseInt(stageRefreshTime, 10)
        : 5; /* minimum value is 5 */
    logger.debug("TimerStarted");
    setInterval(async () => {
      try {
        await this.refreshToken(false);
      } catch (error) {
        logger.error("refreshError-refreshTokeTimer", error);
      }
    }, 1000 * 60 * (refreshTime - 1));
  };

  private refreshToken = async (timer = true) => {
    logger.debug("RefreshToken start");

    try {
      const idToken = Cache.getItem("token");
      const previousRefreshToken = Cache.getItem("refresh");
      logger.debug("refreshToken", previousRefreshToken);
      if (!previousRefreshToken) {
        logger.error("Refresh Token not found!");
        return;
      }

      const result = (await API.graphql(
        {
          query: gql`
            query getNewTokens($refreshToken: String!) {
              getNewTokens(refreshToken: $refreshToken) {
                data {
                  access
                  refresh
                  token
                }
                error {
                  code
                  message
                }
              }
            }
          `,
          variables: {
            refreshToken: previousRefreshToken,
          },
          authMode: AuthType.iamAuth,
        },
        { idToken, appClientId: process.env.REACT_APP_CLIENT_ID as string },
      )) as GraphQLResult<RefreshTokenQuery>;
      const tokenData = result.data?.getNewTokens?.data;
      logger.debug("tokenData", { tokenData });
      if (tokenData) {
        const { token, access, refresh } = tokenData ?? false;
        this.setTokenLocalStorage(token as string, access as string, refresh as string);
        await setAuth(token as string, access as string, refresh as string);
        if (timer) {
          this.refreshTokeTimer();
        }
      }
    } catch (error) {
      logger.error("refreshToken-ERROR", error);
    }
  };
}

export default Auth;
