import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Routers from "../routes";
import Auth from "../services/auth";
import { getUserRole } from "../services/getUserRole";
import { IGetUser } from "../services/types";
import { AppContext, AppContextType, ITitleBarData } from "../utils/contextLib";
import { Editor } from "slate";

function Page() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [dashboardTitle, setDashboardTitle] = useState<string>("Question Sets");
  const [questionSetData, setQuestionSetData] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [username, setUsername] = useState("");
  const [titleBarData, setTitleBarData] = useState<ITitleBarData>({
    title: "Question Sets",
    subTitle: undefined,
    questionTitle: undefined,
    backButton: false,
  });
  const [userID, setUserID] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
  const [isMathJaxModalOpen, setIsMathJaxModalOpen] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);
  const location = useLocation();
  const navigation = useNavigate();

  const values: AppContextType = React.useMemo(
    () => ({
      dashboardTitle,
      isAuthenticated,
      questionSetData,
      setDashboardTitle,
      setIsAuthenticated,
      setQuestionSetData,
      titleBarData,
      setTitleBarData,
      setUserFullName,
      setUserID,
      setUsername,
      setUserRole,
      userFullName,
      userID,
      username,
      userRole,
      isImagePickerOpen,
      setIsImagePickerOpen,
      editor,
      setEditor,
      isMathJaxModalOpen,
      setIsMathJaxModalOpen,
    }),
    [
      dashboardTitle,
      isAuthenticated,
      questionSetData,
      setDashboardTitle,
      setIsAuthenticated,
      setQuestionSetData,
      titleBarData,
      setTitleBarData,
      setUserFullName,
      setUserID,
      setUsername,
      setUserRole,
      userFullName,
      userID,
      username,
      userRole,
      isImagePickerOpen,
      setIsImagePickerOpen,
      editor,
      setEditor,
      isMathJaxModalOpen,
      setIsMathJaxModalOpen,
    ],
  );

  useEffect(() => {
    const currentUserSession = Auth.currentUserSession();
    if (currentUserSession) {
      setIsAuthenticated(true);
      setUsername(currentUserSession.name);
    } else {
      setIsAuthenticated(false);
    }
  }, [location, navigation]);

  useEffect(() => {
    if (isAuthenticated && userRole === "") {
      getUserRole()
        .then(data => {
          const {
            getUser: {
              data: {
                userPersonalInfo: { fullName, userId, userRole: activeUserRole },
              },
            },
          } = data as IGetUser;
          setUserRole(activeUserRole);
          setUserFullName(fullName);
          setUserID(userId);
        })
        .catch(error => {
          return error;
        });
    }
  }, [isAuthenticated]);

  return (
    <AppContext.Provider value={values}>
      <Routers />
    </AppContext.Provider>
  );
}

export default Page;
